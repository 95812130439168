import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import AppBody from "./AppBody";
import AppHeader from "./AppHeader";

const useStyles = makeStyles((theme) => {
  return {
    mainContainer: {
      margin: "0 10px 10px 10px",
    },
    headerContainer: {
      margin: "0px 20px 0px 20px",
    },
    bodyContainer: {
      margin: "0 0 10px 0",
    },
    [theme.breakpoints.down("md")]: {
      mainContainer: {
        margin: "0",
      },
    },
  };
});

const AppContainer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.headerContainer}>
        <AppHeader />
      </Box>
      <Box className={classes.bodyContainer}>
        <AppBody />
      </Box>
    </Box>
  );
};

export default AppContainer;

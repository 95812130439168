import React, { useRef, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { CONFIG } from "../../config";

const mapRender = (status) => {
  if (status === Status.LOADING) return <p>Google map loading...</p>;
  if (status === Status.FAILURE) return <p>Google map Error...</p>;
  return null;
};

const MapDom = ({ map, setMap, ...props }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, props));
    }
  }, [ref, map, props, setMap]);

  return (
    <div
      ref={ref}
      id="map"
      style={{
        width: "100%",
        height: "450px",
        border: "1px solid bisque",
      }}
    ></div>
  );
};

const Map = (props) => {
  const { latLng, map, setMap } = props;

  return (
    <Wrapper apiKey={CONFIG.MAP.API_KEY} render={mapRender} libraries={["places"]}>
      <MapDom
        zoom={CONFIG.MAP.DEFAULT_ZOOM}
        center={latLng || CONFIG.MAP.CENTER_LAT_LNG}
        map={map}
        setMap={setMap}
      ></MapDom>
    </Wrapper>
  );
};

export default Map;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { api } from "../api-services/order";
import AppRoutes from "./AppRoutes";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: { main: "#00436a" },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 0,
        md: 800,
        lg: 800,
        xl: 800,
      },
    },
  });

  return (
    <ApiProvider api={api}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </BrowserRouter>
    </ApiProvider>
  );
};

export default App;
